const TIMEOUT = 20000;
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const checkStatus = async (response, fetchTimeOut, apiName = "", callBack) => {
  if (response && response.status) {
    console.log("status", response.status, apiName);
  }
  if (fetchTimeOut) {
    clearTimeout(fetchTimeOut);
  }
};

export const getLanguages = async () => {
  let fetchTimeOut;

  try {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchTimeOut = setTimeout(() => {
      controller.abort();
      console.log("getLanguages fetch aborted");
      console.log(signal);
    }, TIMEOUT);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "*/*");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      signal: signal,
    };

    const response = await fetch(
      `${baseUrl}/company/languages`,
      requestOptions
    );
    await checkStatus(response, fetchTimeOut, "getLanguages", () =>
      getLanguages()
    );

    const result = await response.json();
    return result;
  } catch (err) {
    if (fetchTimeOut) {
      clearTimeout(fetchTimeOut);
    }
    console.log("error from getLanguages", err);
  }
};

export const updateCustomer = async (jwt, clientDetailsObj) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "*/*");

    var raw = JSON.stringify(clientDetailsObj);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${baseUrl}/rfi/update-customer/${jwt}`,
      requestOptions
    );
    const statusResult = await checkStatus(
      response,
      null,
      "updateCustomer",
      () => updateCustomer(jwt, clientDetailsObj)
    );

    if (statusResult && statusResult?.status && statusResult?.result) {
      return statusResult?.result;
    } else {
      const result = await response.json();
      return result;
    }
  } catch (err) {
    console.log("error from updateCustomer", err);
  }
};

export const getCompanyInfoWithJwt = async (jwt) => {
  let fetchTimeOut;

  try {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchTimeOut = setTimeout(() => {
      controller.abort();
      console.log("getCompanyInfoWithJwt fetch aborted");
      console.log(signal);
    }, TIMEOUT);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "*/*");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      signal: signal,
    };

    const response = await fetch(
      `${baseUrl}/rfi/rfi-company-info/${jwt}`,
      requestOptions
    );
    await checkStatus(response, fetchTimeOut, "getCompanyInfoWithJwt", () =>
      getCompanyInfoWithJwt(jwt)
    );

    const result = await response.json();
    return result;
  } catch (err) {
    if (fetchTimeOut) {
      clearTimeout(fetchTimeOut);
    }
    console.log("error from getCompanyInfoWithJwt", err);
  }
};

export const checkCustomerUpdatable = async (jwt) => {
  let fetchTimeOut;

  try {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchTimeOut = setTimeout(() => {
      controller.abort();
      console.log("checkCustomerUpdatable fetch aborted");
      console.log(signal);
    }, TIMEOUT);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "*/*");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      signal: signal,
    };

    const response = await fetch(
      `${baseUrl}/rfi/check-update-customer/${jwt}`,
      requestOptions
    );
    await checkStatus(response, fetchTimeOut, "checkCustomerUpdatable", () =>
      checkCustomerUpdatable(jwt)
    );

    const result = await response.json();
    return result;
  } catch (err) {
    if (fetchTimeOut) {
      clearTimeout(fetchTimeOut);
    }
    console.log("error from checkCustomerUpdatable", err);
  }
};

export const getCustomerCustomFields = async (jwt) => {
  let fetchTimeOut;

  try {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchTimeOut = setTimeout(() => {
      controller.abort();
      console.log("getCustomerCustomFields fetch aborted");
      console.log(signal);
    }, TIMEOUT);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "*/*");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      signal: signal,
    };

    const response = await fetch(
      `${baseUrl}/rfi/get-custom-fields/${jwt}`,
      requestOptions
    );
    await checkStatus(response, fetchTimeOut, "getCustomerCustomFields", () =>
      getCustomerCustomFields(jwt)
    );

    const result = await response.json();
    return result;
  } catch (err) {
    if (fetchTimeOut) {
      clearTimeout(fetchTimeOut);
    }
    console.log("error from getCustomerCustomFields", err);
  }
};
