import React from "react";
import { useTranslation } from "../context/Language/LanguageContext";
import { TextField } from "@mui/material";
import styled from "styled-components";

const StyledTextField = styled(TextField)`
  & .MuiSelect-icon {
    right: ${(props) => (props.language === "fa" ? "auto" : "0")};
    left: ${(props) => (props.language === "fa" ? "0" : "auto")};
  }
`;

const TxtField = (props) => {
  const { language } = useTranslation();

  return (
    <StyledTextField
      language={language}
      {...props}
      InputLabelProps={{
        ...props.InputLabelProps,
        style:
          language === "fa"
            ? { right: 0, left: "auto" }
            : { left: 0, right: "auto" },
      }}
    />
  );
};

export default TxtField;
