import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "../../context/Language/LanguageContext";

import {
  updateCustomer,
  getCompanyInfoWithJwt,
  checkCustomerUpdatable,
  getCustomerCustomFields,
} from "../../api/api";

import jwt_decode from "jwt-decode";
import Navbar from "../../components/Navbar";
import Txt from "../../components/Txt";
import ToastItAlert from "../../components/utils/ToastItAlert";
import ToastIt from "../../components/utils/ToastIt";

import CustomerRfiForm from "./CustomerRfiForm";
import "./CustomerRfiPage.css";
import Loading from "../../components/Loading";

const initalClientDetails = {
  salutation: "",
  firstName: "",
  lastName: "",
  companyName: "",
  email: "",
  phone: "",
  mobile: "",
};
const initalContactPersonDetails = {
  salutation: "",
  firstName: "",
  lastName: "",
  email: "",
  workPhone: "",
  mobile: "",
  designation: "",
  department: "",
};

const initialBillingAddress = {
  street1: "",
  street2: "",
  city: "",
  countryRegion: "",
  state: "",
  zipCode: "",
  phone: "",
};
const initialShippingAddress = {
  street1: "",
  street2: "",
  city: "",
  countryRegion: "",
  state: "",
  zipCode: "",
  phone: "",
};

function isEmptyString(value) {
  return typeof value === "string" && value.trim() === "";
}

const CustomerRfiPage = () => {
  const { t } = useTranslation();
  const { jwt } = useParams();
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [companyInfo, setCompanyInfo] = useState();

  const [clientDetails, setClientDetails] = useState(initalClientDetails);
  const [billingAddress, setBillingAddress] = useState(initialBillingAddress);
  const [shippingAddress, setShippingAddress] = useState(
    initialShippingAddress
  );
  const [contactPersonDetails, setContactPersonDetails] = useState(
    initalContactPersonDetails
  );

  const [fields, setFields] = useState([]);

  const [isSavingClient, setIsSavingClient] = useState(false);
  const [hasSavedClient, setHasSavedClient] = useState(false);

  const onChangeText = (value, propertyName) => {
    const tmpClientDetails = { ...clientDetails };

    tmpClientDetails[propertyName] = value;
    setClientDetails(tmpClientDetails);
  };
  const onChangeContactPersonText = (value, propertyName) => {
    const tmpContactDetails = { ...contactPersonDetails };

    tmpContactDetails[propertyName] = value;
    setContactPersonDetails(tmpContactDetails);
  };

  const handleCustomFieldChange = (fieldId, value) => {
    const tmpFields = [...fields];
    const changedFields = tmpFields.map((f) => {
      if (f.fieldId === fieldId) {
        f.value = value;
      }
      return f;
    });
    setFields(changedFields);
  };

  const onChangeBillingAddressText = (value, propertyName) => {
    const tmpBillingAddress = { ...billingAddress };

    tmpBillingAddress[propertyName] = value;
    setBillingAddress(tmpBillingAddress);
  };
  const onChangeShippingAddressText = (value, propertyName) => {
    const tmpShippingAddress = { ...shippingAddress };

    tmpShippingAddress[propertyName] = value;
    setShippingAddress(tmpShippingAddress);
  };

  const decodeJwt = async () => {
    try {
      setIsLoading(true);
      if (!jwt) {
        setIsValid(false);
        setIsLoading(false);

        return;
      }
      const decoded = jwt_decode(jwt);
      console.log("decoded", decoded);
      if (!decoded?.exp) {
        setIsValid(false);
        setIsLoading(false);

        return;
      }

      if (
        decoded &&
        decoded?.exp &&
        decoded?.exp < Math.floor(Date.now() / 1000)
      ) {
        setIsValid(false);
        setIsLoading(false);

        return;
      }

      const checkResult = await checkCustomerUpdatable(jwt);
      if (!checkResult || !checkResult?.success || !checkResult?.itemInfo) {
        setIsValid(false);
        setIsLoading(false);

        return;
      }

      const result = await getCompanyInfoWithJwt(jwt);
      console.log("result", result);
      if (!result || !result?.success) {
        setIsValid(false);
        setIsLoading(false);

        return;
      }
      if (result && result?.success) {
        setIsValid(true);
        setCompanyInfo(result?.itemInfo);
      }

      const customFieldResult = await getCustomerCustomFields(jwt);
      console.log("customFieldResult", customFieldResult);

      if (customFieldResult && customFieldResult?.success) {
        if (
          customFieldResult?.itemInfo &&
          customFieldResult?.itemInfo?.fields
        ) {
          const modifiedFields = customFieldResult?.itemInfo?.fields.map(
            (f) => ({
              fieldId: f.fieldId,
              name: f.name,
              type: f.type,
              value: "",
            })
          );
          setFields(modifiedFields);
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsValid(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    decodeJwt();
  }, []);

  useEffect(() => {
    console.log("fields", fields);
  }, [fields]);

  const handleSaveClient = async () => {
    try {
      setIsSavingClient(true);
      const clientDetailsObj = { ...clientDetails };
      clientDetailsObj.billingAddress = { ...billingAddress };
      clientDetailsObj.shippingAddress = { ...shippingAddress };
      clientDetailsObj.contactPerson = { ...contactPersonDetails };

      const nonEmptyFields = fields?.filter(
        (f) => f.value !== null && !isEmptyString(f.value)
      );
      clientDetailsObj.customFields = nonEmptyFields;
      console.log(nonEmptyFields);

      const result = await updateCustomer(jwt, clientDetailsObj);
      console.log(result);
      if (result && result.success) {
        ToastIt(result.message);
        setIsSavingClient(false);
        setHasSavedClient(true);
      } else if (result?.message) {
        ToastItAlert(t(`${result?.message}`));
        setIsSavingClient(false);
      } else {
        ToastItAlert(`${t("clientProblem")}`);
      }
      setIsSavingClient(false);
    } catch (error) {
      console.log(error);
      ToastItAlert(`${t("clientProblem")}`);
      setIsSavingClient(false);
    }
  };

  const copyBillingAddress = () => {
    console.log("handling click in copy billing address");
    const tmpShippingAddress = { ...billingAddress };
    console.log("tmpShippingAddress", tmpShippingAddress);
    setShippingAddress(tmpShippingAddress);
  };

  useEffect(() => {
    console.log(shippingAddress);
  }, [shippingAddress]);

  if (!isValid) {
    return (
      <>
        <Navbar />

        <Txt>{t("LINK_NOT_VALID")}</Txt>
      </>
    );
  }

  // if (isLoading || isSavingClient) {
  //   <div style={{ display: "flex", width: "100%", height: "400px" }}>
  //     <Loading />
  //   </div>;
  // }

  return (
    <div>
      <Navbar companyInfo={companyInfo} isLoading={isLoading} />
      {(isLoading || isSavingClient) && (
        <div style={{ display: "flex", width: "100%", height: "400px" }}>
          <Loading />
        </div>
      )}
      {!isLoading && !isSavingClient && !hasSavedClient && isValid && (
        <CustomerRfiForm
          clientDetails={clientDetails}
          billingAddress={billingAddress}
          shippingAddress={shippingAddress}
          contactPersonDetails={contactPersonDetails}
          onChangeText={onChangeText}
          onChangeContactPersonText={onChangeContactPersonText}
          onChangeBillingAddressText={onChangeBillingAddressText}
          onChangeShippingAddressText={onChangeShippingAddressText}
          copyBillingAddress={copyBillingAddress}
          handleSaveClient={handleSaveClient}
          isLoading={isLoading}
          isSavingClient={isSavingClient}
          companyInfo={companyInfo}
          fields={fields}
          handleCustomFieldChange={handleCustomFieldChange}
        />
      )}
      {hasSavedClient && (
        <div>
          <Txt>{t("THANKS_CUSTOMER_RFI")}</Txt>
        </div>
      )}
    </div>
  );
};

export default CustomerRfiPage;
