import React from "react";
import "./Txt.css";
import { useTranslation } from "../context/Language/LanguageContext";

const Txt = (props) => {
  const { language } = useTranslation();
  return (
    <p
      className={props.className}
      style={{
        ...props.style,
        fontFamily: language === "fa" ? "IranSans" : "Roboto",
      }}
    >
      {props.children}
    </p>
  );
};

export default Txt;
