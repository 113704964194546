import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Switch from "@mui/material/Switch";

import moment from "moment";

import { useTranslation } from "../context/Language/LanguageContext";
import Txt from "./Txt";

const CustomField = (props) => {
  const { t } = useTranslation();

  if (!props?.field || !props?.field?.type || !props?.field?.fieldId) {
    return null;
  }

  const handleBooleanChange = async (event) => {
    console.log(event.target.checked);
    props.handleCustomFieldChange(props?.field?.fieldId, event.target.checked);
  };

  if (props.field?.type === "TEXT") {
    return (
      <TextField
        id={props?.field?.name}
        name={props?.field?.name}
        label={props?.field?.name}
        fullWidth
        autoComplete="given-name"
        variant="standard"
        value={props?.field?.value}
        onChange={(event) => {
          props.handleCustomFieldChange(
            props?.field?.fieldId,
            event?.target?.value
          );
        }}
      />
    );
  }
  if (props.field?.type === "NUMBER") {
    return (
      <TextField
        id={props?.field?.name}
        name={props?.field?.name}
        label={props?.field?.name}
        inputMode="number"
        inputProps={{
          type: "number",
          inputMode: "number",
        }}
        fullWidth
        autoComplete="given-name"
        variant="standard"
        value={props?.field?.value}
        onChange={(event) => {
          props.handleCustomFieldChange(
            props?.field?.fieldId,
            event?.target?.value
          );
        }}
      />
    );
  }
  if (props.field?.type === "DATE") {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <MobileDatePicker
          value={props?.field?.value ? moment(props?.field?.value) : null}
          onChange={(newValue) => {
            props.handleCustomFieldChange(
              props?.field?.fieldId,
              moment(newValue)?.valueOf()
            );
          }}
          fullWidth
          label={props?.field?.name}
          sx={{ width: "100%" }}
        />
        {props?.field?.value && (
          <button
            style={{ backgroundColor: "transparent", marginLeft: "10px" }}
            onClick={() =>
              props.handleCustomFieldChange(props?.field?.fieldId, "")
            }
          >
            <Txt style={{ fontSize: 14 }}>{t("clear")}</Txt>
          </button>
        )}
      </div>
    );
  }
  if (props.field?.type === "BOOLEAN") {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "56px",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: "4px",
          paddingLeft: "10px",
        }}
      >
        {props?.field?.name && (
          <Txt style={{ fontSize: "16px", color: "#666666" }}>
            {props?.field?.name}
          </Txt>
        )}
        <Switch
          checked={
            props?.field?.value && props?.field?.value !== "false"
              ? true
              : false
          }
          onChange={handleBooleanChange}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            "&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
              backgroundColor: "#71c53d",
            },
            "&.MuiSwitch-root .Mui-checked": {
              color: "var(--primary)",
            },
          }}
        />
      </div>
    );
  }
};

export default CustomField;
