import React from "react";
import { Outlet, Link } from "react-router-dom";

// import Navbar from "./Navbar";

const Layout = () => {
  return (
    <div style={{ width: "100%", maxWidth: 1140 }}>
      <div className="page">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
