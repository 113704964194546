import React, { useState } from "react";
import styled from "@emotion/styled";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";

import PersonalInfoForm from "./PersonalInfoForm";
import BillingInfoForm from "./BillingInfoForm";
import ShippingInfoForm from "./ShippingInfoForm";

import Txt from "../../components/Txt";

import { useTranslation } from "../../context/Language/LanguageContext";
import { hasTrueProperty } from "../../components/utils/hasTrueProperty";

function Copyright(props) {
  const { t } = useTranslation();
  if (props?.isPro) {
    return <p></p>;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Txt style={{ color: "var(--sideMenuOverlay)", fontSize: 14 }}>
        {t("RFI_COPYRIGHT_PART_1")}
        <a
          style={{ color: "var(--primary)", fontWeight: "500", fontSize: 14 }}
          href={process.env.REACT_APP_WEBSITE_URL}
        >
          {t("RFI_COPYRIGHT_PART_2")}
        </a>
        {t("RFI_COPYRIGHT_PART_3")}
      </Txt>
    </div>
  );
}

const steps = ["PERSONAL_INFO", "BILLING_INFO", "SHIPPING_INFO"];

const initialErrors = {
  firstName: false,
  lastName: false,
  email: false,
  phone: false,
  mobile: false,
  contactEmail: false,
  contactPhone: false,
  contactMobile: false,
  billingPhone: false,
  shippingPhone: false,
};

export default function CustomerRfiForm(props) {
  const [activeStep, setActiveStep] = useState(0);
  const { t, language } = useTranslation();

  const StyledStepLabel = styled(StepLabel)`
    & .MuiStepLabel-iconContainer.Mui-active {
      padding-right: ${language === "fa" ? "0px" : "8px"};
      padding-left: ${language === "fa" ? "8px" : "0px"};
    }
    & .MuiStepLabel-iconContainer.Mui-disabled {
      padding-right: ${language === "fa" ? "0px" : "8px"};
      padding-left: ${language === "fa" ? "8px" : "0px"};
    }
  `;

  const regex = {
    email: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
    phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  };

  const {
    clientDetails,
    billingAddress,
    shippingAddress,
    contactPersonDetails,
    onChangeText,
    onChangeContactPersonText,
    onChangeBillingAddressText,
    onChangeShippingAddressText,
    copyBillingAddress,
    handleSaveClient,
    isLoading,
    isSavingClient,
    companyInfo,
    fields,
    handleCustomFieldChange,
  } = props;

  const [errors, setErrors] = useState(initialErrors);

  const handleNext = () => {
    const tmpErrors = { ...initialErrors };

    if (activeStep === 0) {
      if (!clientDetails?.firstName) {
        tmpErrors.firstName = true;
      }
      if (!clientDetails?.lastName) {
        tmpErrors.lastName = true;
      }
      if (clientDetails?.email) {
        const testResult = regex?.email.test(clientDetails?.email);
        if (!testResult) {
          tmpErrors.email = true;
        }
      }
      if (clientDetails?.phone) {
        const testResult = regex?.phone.test(clientDetails?.phone);
        if (!testResult) {
          tmpErrors.phone = true;
        }
      }
      if (clientDetails?.mobile) {
        const testResult = regex?.phone.test(clientDetails?.mobile);
        if (!testResult) {
          tmpErrors.mobile = true;
        }
      }

      if (contactPersonDetails?.email) {
        const testResult = regex?.email.test(contactPersonDetails?.email);
        if (!testResult) {
          tmpErrors.contactEmail = true;
        }
      }
      if (contactPersonDetails?.workPhone) {
        const testResult = regex?.phone.test(contactPersonDetails?.workPhone);
        if (!testResult) {
          tmpErrors.contactPhone = true;
        }
      }
      if (contactPersonDetails?.mobile) {
        const testResult = regex?.phone.test(contactPersonDetails?.mobile);
        if (!testResult) {
          tmpErrors.contactMobile = true;
        }
      }

      if (hasTrueProperty(tmpErrors)) {
        setErrors(tmpErrors);
        return;
      } else {
        setErrors(initialErrors);
      }
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 1) {
      if (billingAddress?.phone) {
        const testResult = regex?.phone.test(billingAddress?.phone);
        if (!testResult) {
          tmpErrors.billingPhone = true;
        }
      }
      if (hasTrueProperty(tmpErrors)) {
        setErrors(tmpErrors);
        return;
      } else {
        setErrors(initialErrors);
      }
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 2) {
      if (shippingAddress?.phone) {
        const testResult = regex?.phone.test(shippingAddress?.phone);
        if (!testResult) {
          tmpErrors.shippingPhone = true;
        }
      }
      if (hasTrueProperty(tmpErrors)) {
        setErrors(tmpErrors);
        return;
      } else {
        setErrors(initialErrors);
      }
      handleSaveClient();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <PersonalInfoForm
            clientDetails={clientDetails}
            onChangeText={onChangeText}
            contactPersonDetails={contactPersonDetails}
            onChangeContactPersonText={onChangeContactPersonText}
            errors={errors}
            fields={fields}
            handleCustomFieldChange={handleCustomFieldChange}
          />
        );
      case 1:
        return (
          <BillingInfoForm
            billingAddress={billingAddress}
            onChangeBillingAddressText={onChangeBillingAddressText}
            errors={errors}
          />
        );
      case 2:
        return (
          <ShippingInfoForm
            shippingAddress={shippingAddress}
            onChangeShippingAddressText={onChangeShippingAddressText}
            copyBillingAddress={copyBillingAddress}
            errors={errors}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const StepCircle = ({ active, completed, icon }) => {
    const getBackgroundColor = () => {
      if (active || completed) {
        return "var(--primary)";
      } else {
        return "var(--mediumBlueGray)";
      }
    };
    return (
      <div
        className="step-circle"
        style={{ backgroundColor: getBackgroundColor() }}
      >
        <Txt
          className={`${active || completed ? "step-number-completed" : ""}`}
        >
          {icon}
        </Txt>
      </div>
    );
  };

  return (
    <>
      <CssBaseline />

      <Container component="main" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <div className="rfi-form-title-container">
            <Txt style={{ fontSize: "24px", fontWeight: "500" }}>
              {t("information")}
            </Txt>
          </div>
          <div className="stepper-container">
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step
                  key={label}
                  sx={{
                    "& .MuiStepLabel-label.Mui-completed": {
                      color: "var(--primary)", // Just text label (COMPLETED)
                    },
                    "& .MuiStepLabel-label.Mui-active": {
                      color: "var(--primary)", // Just text label (ACTIVE)
                    },
                  }}
                >
                  <StyledStepLabel StepIconComponent={StepCircle}>
                    {t(label)}
                  </StyledStepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          <React.Fragment>
            {getStepContent(activeStep)}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {activeStep !== 0 && (
                <Button
                  onClick={handleBack}
                  sx={{
                    mt: 3,
                    ml: 1,
                    backgroundColor: "var(--major)",
                    color: "var(--minor)",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "var(--primary)",
                    },
                  }}
                >
                  {t("BACK")}
                </Button>
              )}

              <Button
                variant="contained"
                onClick={handleNext}
                sx={{
                  mt: 3,
                  ml: 1,
                  backgroundColor: "var(--primary)",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "var(--primary)",
                  },
                }}
                disabled={isLoading || isSavingClient}
              >
                {activeStep === steps.length - 1
                  ? `${t("save")}`
                  : `${t("next")}`}
              </Button>
            </Box>
          </React.Fragment>
        </Paper>
        <Copyright isPro={companyInfo && companyInfo?.isPro} />
      </Container>
    </>
  );
}
