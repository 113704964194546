import { createContext, useContext, useState, useEffect } from "react";
import getTranslationFile from "./getTranslationFile";

const LanguageContext = createContext();

export const useTranslation = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useTranslation must be used within a LanguageProvider");
  }
  return context;
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    process.env.REACT_APP_DEFAULT_LANGUAGE
  );
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("language changed", language);
    const fetchTranslations = async () => {
      setLoading(true);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/translation/${language}`
        );
        if (!response.ok) {
          console.log(response.status);
          throw new Error("Failed to fetch user translations");
        }
        const data = await response.json();
        console.log("data", data);
        setTranslations(data);
      } catch (error) {
        console.log("Error fetching translations:", error);
        const translationFile = getTranslationFile(language);
        setTranslations(translationFile);
      } finally {
        setLoading(false);
      }
    };

    fetchTranslations();
  }, [language]);

  const t = (key) => {
    if (translations[key] && translations[key] !== "") {
      return translations[key];
    } else if (translations[key] === "") {
      return "";
    } else {
      return key;
    }
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        t,
        loading,
        setLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
