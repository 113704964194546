import * as React from "react";
import Dialog from "@mui/material/Dialog";

function CenterScreenModal(props) {
  return (
    <div>
      <Dialog
        open={props.isModalVisible}
        onClose={props.onModalClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        sx={{
          "& .MuiDialog-paper	": {
            backgroundColor: "var(--major)", // Just text label (COMPLETED)
            width: "80vw",
            maxWidth: "400px",
            padding: "50px 35px",
            borderRadius: "20px",
          },

          // "& .MuiStepLabel-label.Mui-active": {
          //   color: "var(--primary)", // Just text label (ACTIVE)
          // },
        }}
      >
        {props?.children}
      </Dialog>
    </div>
  );
}

export default CenterScreenModal;
