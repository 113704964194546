import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import TxtField from "../../components/TxtField";

import CustomField from "../../components/CustomField";

import { useTranslation } from "../../context/Language/LanguageContext";

const SALUTATIONS = ["MR", "MS", "DR"];

export default function PersonalInfoForm({
  clientDetails,
  onChangeText,
  onChangeContactPersonText,
  contactPersonDetails,
  errors,
  regex,
  fields,
  handleCustomFieldChange,
}) {
  const { t, language } = useTranslation();

  const renderCustomFields = fields?.map((field) => (
    <Grid item xs={12} sm={6} key={field?.fieldId}>
      <CustomField
        field={field}
        handleCustomFieldChange={handleCustomFieldChange}
      />
    </Grid>
  ));

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {t("PERSONAL-CONTACT-INFO")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3} lg={2}>
          <TxtField
            id="select-salutation"
            select
            label={t("salutations")}
            variant="standard"
            fullWidth
            value={clientDetails?.salutation}
            onChange={(event) => {
              onChangeText(event.target.value, "salutation");
            }}
          >
            {SALUTATIONS.map((option) => (
              <MenuItem key={option} value={option}>
                {t(option)}
              </MenuItem>
            ))}
          </TxtField>
        </Grid>
        <Grid item xs={12} sm={4} lg={5}>
          <TxtField
            required
            id="firstName"
            name="firstName"
            label={t("firstName")}
            fullWidth
            autoComplete="given-name"
            variant="standard"
            value={clientDetails?.firstName}
            onChange={(event) => {
              onChangeText(event.target.value, "firstName");
            }}
            error={errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={5} lg={5}>
          <TxtField
            required
            id="lastName"
            name="lastName"
            label={t("lastName")}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            value={clientDetails?.lastName}
            onChange={(event) => {
              onChangeText(event.target.value, "lastName");
            }}
            error={errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <TxtField
            id="companyName"
            name="companyName"
            label={t("organization")}
            fullWidth
            variant="standard"
            value={clientDetails?.companyName}
            onChange={(event) => {
              onChangeText(event.target.value, "companyName");
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TxtField
            id="email"
            name="email"
            label={t("email")}
            fullWidth
            variant="standard"
            value={clientDetails?.email}
            onChange={(event) => {
              onChangeText(event.target.value, "email");
            }}
            error={errors?.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="phone"
            name="phone"
            label={t("phone")}
            inputMode="tel"
            inputProps={{
              type: "tel",
              inputMode: "tel",
            }}
            fullWidth
            variant="standard"
            value={clientDetails?.phone}
            onChange={(event) => {
              onChangeText(event.target.value, "phone");
            }}
            error={errors?.phone}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="mobile"
            name="mobile"
            label={t("mobile")}
            inputMode="tel"
            inputProps={{
              type: "tel",
              inputMode: "tel",
            }}
            fullWidth
            variant="standard"
            value={clientDetails?.mobile}
            onChange={(event) => {
              onChangeText(event.target.value, "mobile");
            }}
            error={errors?.mobile}
          />
        </Grid>
        {renderCustomFields}
      </Grid>
      <Typography variant="h6" gutterBottom sx={{ mt: 5 }}>
        {t("addContactPerson")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3} lg={2}>
          <TxtField
            id="select-salutation"
            select
            label={t("salutations")}
            variant="standard"
            fullWidth
            value={contactPersonDetails?.salutation}
            onChange={(event) => {
              onChangeContactPersonText(event.target.value, "salutation");
            }}
          >
            {SALUTATIONS.map((option) => (
              <MenuItem key={option} value={option}>
                {t(option)}
              </MenuItem>
            ))}
          </TxtField>
        </Grid>
        <Grid item xs={12} sm={4} lg={5}>
          <TxtField
            id="firstName"
            name="firstName"
            label={t("firstName")}
            fullWidth
            autoComplete="given-name"
            variant="standard"
            value={contactPersonDetails?.firstName}
            onChange={(event) => {
              onChangeContactPersonText(event.target.value, "firstName");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5} lg={5}>
          <TxtField
            id="lastName"
            name="lastName"
            label={t("lastName")}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            value={contactPersonDetails?.lastName}
            onChange={(event) => {
              onChangeContactPersonText(event.target.value, "lastName");
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TxtField
            id="companyName"
            name="companyName"
            label={t("organization")}
            fullWidth
            variant="standard"
            value={contactPersonDetails?.companyName}
            onChange={(event) => {
              onChangeContactPersonText(event.target.value, "companyName");
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TxtField
            id="email"
            name="email"
            label={t("email")}
            fullWidth
            variant="standard"
            value={contactPersonDetails?.email}
            onChange={(event) => {
              onChangeContactPersonText(event.target.value, "email");
            }}
            error={errors.contactEmail}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="workPhone"
            name="workPhone"
            label={t("phoneNumber")}
            inputMode="tel"
            inputProps={{
              type: "tel",
              inputMode: "tel",
            }}
            fullWidth
            variant="standard"
            value={contactPersonDetails?.workPhone}
            onChange={(event) => {
              onChangeContactPersonText(event.target.value, "workPhone");
            }}
            error={errors.contactPhone}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="mobile"
            name="mobile"
            label={t("mobile")}
            inputMode="tel"
            inputProps={{
              type: "tel",
              inputMode: "tel",
            }}
            fullWidth
            variant="standard"
            value={contactPersonDetails?.mobile}
            onChange={(event) => {
              onChangeContactPersonText(event.target.value, "mobile");
            }}
            error={errors.contactMobile}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="designation"
            name="designation"
            label={t("designation")}
            fullWidth
            variant="standard"
            value={contactPersonDetails?.designation}
            onChange={(event) => {
              onChangeContactPersonText(event.target.value, "designation");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="department"
            name="department"
            label={t("department")}
            fullWidth
            variant="standard"
            value={contactPersonDetails?.department}
            onChange={(event) => {
              onChangeContactPersonText(event.target.value, "department");
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
