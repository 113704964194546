import translationEN from "./TranslationFiles/translationEN.json";
import translationAR from "./TranslationFiles/translationAR.json";
import translationDE from "./TranslationFiles/translationDE.json";
import translationES from "./TranslationFiles/translationES.json";
import translationFA from "./TranslationFiles/translationFA.json";
import translationFR from "./TranslationFiles/translationFR.json";
import translationTR from "./TranslationFiles/translationTR.json";

const translations = {
  en: translationEN,
  de: translationDE,
  fa: translationFA,
  ar: translationAR,
  tr: translationTR,
  es: translationES,
  fr: translationFR,
};

const getTranslationFile = (lang) => {
  return translations[lang] || translationEN;
};

export default getTranslationFile;
