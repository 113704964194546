import React, { useState, useEffect } from "react";
import { useTranslation } from "../context/Language/LanguageContext";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {
  FaTelegram,
  FaFacebookSquare,
  FaTwitterSquare,
  FaGlobe,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";

import Txt from "./Txt";

import { getLanguages } from "../api/api";
import CenterScreenModal from "./Modals/CenterScreenModal";

import "./Navbar.css";

const Navbar = (props) => {
  const { t, setLanguage, loading, language } = useTranslation();
  const { isLoading, companyInfo } = props;

  console.log("companyInfo", companyInfo);

  const [hasLoadedPreferences, setHasLoadedPreferences] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);

  useEffect(() => {
    const loadPreferenses = async () => {
      if (!hasLoadedPreferences) {
        const result = await getLanguages();
        console.log("result of fetching languages", result);
        if (result && result?.success) {
          setLanguages(result?.items);
        }
        setHasLoadedPreferences(true);
      }
    };
    loadPreferenses();
  }, [hasLoadedPreferences]);

  const handleLanguageChange = async (event) => {
    const selectedItem = event?.target?.value;
    const selectedValue = languages?.find((ln) => ln.name === selectedItem);
    console.log(selectedValue);
    if (!selectedValue || selectedValue?.lang === language) {
      console.log("already selected");
      return;
    }
    setLanguage(selectedValue?.lang);
  };

  const findDefaultValue = () => {
    const selectedIndex = languages?.findIndex((ln) => ln.lang === language);
    if (
      selectedIndex &&
      selectedIndex >= 0 &&
      languages[selectedIndex] &&
      languages[selectedIndex]?.name
    ) {
      return languages[selectedIndex]?.name;
    } else {
      return languages[0]?.name;
    }
  };

  const renderSocialNetworks = companyInfo?.socialNetworks?.map(
    (link, index) => {
      let url;

      if (link.title === "Instagram") {
        url = `https://www.instagram.com/${link.link}`;
        return (
          <a
            key={index}
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{ marginRight: "5px" }}
          >
            <FaInstagram size={"18px"} color="var(--minor)" />
          </a>
        );
      }

      if (link.title === "Facebook") {
        url = `https://www.facebook.com/${link.link}`;
        return (
          <a
            key={index}
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{ marginRight: "5px" }}
          >
            <FaFacebookSquare size={"18px"} color="var(--minor)" />
          </a>
        );
      }

      if (link.title === "WhatsApp") {
        url = `https://wa.me/${link.link}`;
        return (
          <a
            key={index}
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{ marginRight: "5px" }}
          >
            <FaWhatsapp size={"18px"} color="var(--minor)" />
          </a>
        );
      }

      if (link.title === "Telegram") {
        url = `https://t.me/${link.link}`;
        return (
          <a
            key={index}
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{ marginRight: "5px" }}
          >
            <FaTelegram size={"18px"} color="var(--minor)" />
          </a>
        );
      }

      if (link.title === "Twitter") {
        url = `https://twitter.com/${link.link}`;
        return (
          <a
            key={index}
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{ marginRight: "5px" }}
          >
            <FaTwitterSquare size={"18px"} color="var(--minor)" />
          </a>
        );
      }

      return null;
    }
  );

  return (
    <nav>
      <div className="navbar">
        <div className="logo-container">
          <div className="logo">
            {!isLoading && companyInfo?.name && (
              <>
                {companyInfo?.logoUrl && (
                  <div
                    className="company-logo-image-container"
                    onClick={() => setIsProfileModalVisible(true)}
                  >
                    <img
                      width={"100%"}
                      style={{ objectFit: "contain" }}
                      alt="company-logo"
                      src={companyInfo?.logoUrl}
                    />
                  </div>
                )}
                <div
                  onClick={() => setIsProfileModalVisible(true)}
                  style={{ cursor: "pointer" }}
                >
                  <Txt className="company-logo-name">{companyInfo?.name}</Txt>
                </div>
              </>
            )}
            {!isLoading && !companyInfo?.name && (
              <a href={process.env.REACT_APP_WEBSITE_URL}>Zest</a>
            )}
          </div>
        </div>
        {hasLoadedPreferences && languages?.length > 0 && (
          <FormControl
            variant="standard"
            sx={{
              m: 1,
              minWidth: 80,
            }}
          >
            <Select
              id="select-language"
              value={findDefaultValue()}
              onChange={handleLanguageChange}
              label="Language"
              sx={{
                fontSize: 14,
                color: "var(--minor)",

                "& .MuiSvgIcon-root": {
                  color: "var(--minor)",
                },
              }}
            >
              {languages.map((obj) => (
                <MenuItem
                  key={obj.name}
                  value={obj.name}
                  sx={{ color: "var(--minor)", fontSize: 12 }}
                >
                  {obj.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <CenterScreenModal
        isModalVisible={isProfileModalVisible}
        onModalClose={() => {
          setIsProfileModalVisible(false);
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "20px",
          }}
        >
          {companyInfo?.logoUrl && (
            <div
              style={{
                width: "80px",
                height: "80px",
                overflow: "hidden",
                borderRadius: "80px",
              }}
              onClick={() => setIsProfileModalVisible(true)}
            >
              <img
                width={"100%"}
                style={{
                  objectFit: "contain",
                  objectPosition: "center center",
                }}
                alt="company-logo"
                src={companyInfo?.logoUrl}
              />
            </div>
          )}
          <Txt
            style={{ fontSize: "24px", fontWeight: "700", marginTop: "10px" }}
          >
            {companyInfo?.name}
          </Txt>
          {(companyInfo?.website ||
            (companyInfo?.socialNetworks &&
              companyInfo?.socialNetworks?.length > 0)) && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {companyInfo?.website && (
                <a
                  href={`${
                    companyInfo?.website.startsWith("http")
                      ? companyInfo?.website
                      : `http://${companyInfo?.website}`
                  }`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: "5px" }}
                >
                  <FaGlobe size={"18px"} color="var(--minor)" />
                </a>
              )}
              {renderSocialNetworks}
            </div>
          )}
        </div>
      </CenterScreenModal>
    </nav>
  );
};

export default Navbar;
