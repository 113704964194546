import React, { useContext, useEffect } from "react";
import {
  Routes,
  Route,
  Outlet,
  Link,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import Layout from "./components/Layout";
import HomePage from "./pages/Home/HomePage";
import CustomerRfiPage from "./pages/Customer/CustomerRfiPage";

import { ToastPortal } from "./components/ToastPortal";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useTranslation } from "./context/Language/LanguageContext";

const Root = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="/customer/:jwt" element={<CustomerRfiPage />} />
      </Route>
    </Routes>
  );
};

function App() {
  const { language } = useTranslation();
  const theme = createTheme({
    typography: {
      fontFamily: language === "fa" ? "IranSans" : "Roboto",
    },
    direction: language === "fa" ? "rtl" : "ltr",
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#6ab13d",
      },
    },
    components: {
      // Name of the component
      MuiMenu: {
        styleOverrides: {
          // Name of the slot
          paper: {
            // Some CSS
            backgroundColor: "var(--major)",
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: "#E60060",
            "&.Mui-checked": {
              color: "#16DF97",
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    document.documentElement.dir = language === "fa" ? "rtl" : "ltr";
    document.documentElement.lang = language;
  }, [language]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div className={`screen-container `}>
            <Root />
            <ToastPortal />
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
