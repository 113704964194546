import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TxtField from "../../components/TxtField";

import { useTranslation } from "../../context/Language/LanguageContext";
import Txt from "../../components/Txt";

export default function ShippingInfoForm({
  shippingAddress,
  onChangeShippingAddressText,
  copyBillingAddress,
  errors,
}) {
  const { t } = useTranslation();

  console.log("shippingAddress?.street1", shippingAddress?.street1);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: 30,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {t("shippingAddress")}
        </Typography>
        <button
          style={{
            backgroundColor: "var(--primary)",
            marginBottom: "5px",
            marginInlineStart: "10px",
            padding: "5px 10px",
            borderRadius: "5px",
          }}
          onClick={() => copyBillingAddress()}
        >
          <Txt
            style={{
              fontSize: "12px",
              color: "white",
            }}
          >
            {t("copyBilling")}
          </Txt>
        </button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TxtField
            id="street1"
            name="street1"
            label={t("street1")}
            fullWidth
            variant="standard"
            value={shippingAddress?.street1}
            onChange={(event) => {
              onChangeShippingAddressText(event.target.value, "street1");
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TxtField
            id="street2"
            name="street2"
            label={t("street2")}
            fullWidth
            variant="standard"
            value={shippingAddress?.street2}
            onChange={(event) => {
              onChangeShippingAddressText(event.target.value, "street2");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="city"
            name="city"
            label={t("city")}
            fullWidth
            variant="standard"
            value={shippingAddress?.city}
            onChange={(event) => {
              onChangeShippingAddressText(event.target.value, "city");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="state"
            name="state"
            label={t("state")}
            fullWidth
            variant="standard"
            value={shippingAddress?.state}
            onChange={(event) => {
              onChangeShippingAddressText(event.target.value, "state");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="zipCode"
            name="zipCode"
            label={t("zipCode")}
            fullWidth
            variant="standard"
            value={shippingAddress?.zipCode}
            onChange={(event) => {
              onChangeShippingAddressText(event.target.value, "zipCode");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="countryRegion"
            name="countryRegion"
            label={t("countryRegion")}
            fullWidth
            variant="standard"
            value={shippingAddress?.countryRegion}
            onChange={(event) => {
              onChangeShippingAddressText(event.target.value, "countryRegion");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="phone"
            name="phone"
            label={t("phone")}
            fullWidth
            variant="standard"
            value={shippingAddress?.phone}
            onChange={(event) => {
              onChangeShippingAddressText(event.target.value, "phone");
            }}
            error={errors.shippingPhone}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
