import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TxtField from "../../components/TxtField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { useTranslation } from "../../context/Language/LanguageContext";

export default function BillingInfoForm({
  billingAddress,
  onChangeBillingAddressText,
  errors,
}) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {t("billingAddress")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TxtField
            id="street1"
            name="street1"
            label={t("street1")}
            fullWidth
            variant="standard"
            value={billingAddress?.street1}
            onChange={(event) => {
              onChangeBillingAddressText(event.target.value, "street1");
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TxtField
            id="street2"
            name="street2"
            label={t("street2")}
            fullWidth
            variant="standard"
            value={billingAddress?.street2}
            onChange={(event) => {
              onChangeBillingAddressText(event.target.value, "street2");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="city"
            name="city"
            label={t("city")}
            fullWidth
            variant="standard"
            value={billingAddress?.city}
            onChange={(event) => {
              onChangeBillingAddressText(event.target.value, "city");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="state"
            name="state"
            label={t("state")}
            fullWidth
            variant="standard"
            value={billingAddress?.state}
            onChange={(event) => {
              onChangeBillingAddressText(event.target.value, "state");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="zipCode"
            name="zipCode"
            label={t("zipCode")}
            fullWidth
            variant="standard"
            value={billingAddress?.zipCode}
            onChange={(event) => {
              onChangeBillingAddressText(event.target.value, "zipCode");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="countryRegion"
            name="countryRegion"
            label={t("countryRegion")}
            fullWidth
            variant="standard"
            value={billingAddress?.countryRegion}
            onChange={(event) => {
              onChangeBillingAddressText(event.target.value, "countryRegion");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TxtField
            id="phone"
            name="phone"
            label={t("phone")}
            fullWidth
            variant="standard"
            value={billingAddress?.phone}
            onChange={(event) => {
              onChangeBillingAddressText(event.target.value, "phone");
            }}
            error={errors.billingPhone}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
