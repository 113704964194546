import classes from "./LoadingSpinner.module.css";

const Loading = (props) => {
  const smallClass = props?.small ? classes.smallSpinner : "";

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={`${classes.spinner} ${smallClass}`}></div>
    </div>
  );
};

export default Loading;
